
import { defineComponent } from 'vue';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	components: {
		SVG,
	},
});
